/* MAIN APPLICATION CSS */
/* PATHS */
/* LAYOUT & GRID */
/* FONT */
/* COLORS */
.white {
  color: #FFF;
}
.white-bg {
  background-color: #FFF;
}
.black {
  color: #000;
}
.black-bg {
  background-color: #000;
}
.red {
  color: #f21c0a;
}
.red-bg {
  background-color: #f21c0a;
}
.gray1 {
  color: #bfbfbf;
}
.gray1-bg {
  background-color: #bfbfbf;
}
.gray2 {
  color: #a3a3a3;
}
.gray2-bg {
  background-color: #a3a3a3;
}
.gray3 {
  color: #cbcbcb;
}
.gray3-bg {
  background-color: #cbcbcb;
}
.gray4 {
  color: #f1f1f1;
}
.gray4-bg {
  background-color: #f1f1f1;
}
.gray5 {
  color: #4d4d4d;
}
.gray5-bg {
  background-color: #4d4d4d;
}
.gray6 {
  color: #d2d2d2;
}
.gray6-bg {
  background-color: #d2d2d2;
}
.gray7 {
  color: #f1f1f1;
}
.gray7-bg {
  background-color: #f1f1f1;
}
.gray8 {
  color: #e6e7e8;
}
.gray8-bg {
  background-color: #e6e7e8;
}
.green {
  background-color: #9daf37;
}
.green-bg {
  background-color: #9daf37;
}
.gray-font {
  color: #bfbfbf;
}
.gray-font-dark {
  color: #f1f1f1;
}
.gray-border {
  color: #a3a3a3;
}
.gray-bg {
  background-color: #cbcbcb;
}
/* Buttons */
/* MIXINS */
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
}
.clearfix:after {
  clear: both;
}
/* ICONS */
[class^="icon-"],
[class*=" icon-"],
.is-icon {
  /* use !important to prevent issues with browser extensions that change ../font */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
[class^="ic-old-"],
[class*=" ic-old-"] {
  /* use !important to prevent issues with browser extensions that change ../font */
  font-family: 'icomoon-old' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* FONT */
.font {
  font-family: "Polo", sans-serif;
}
/* COLORS */
@font-face {
  font-family: 'Polo';
  src: url('/font/polor_regular-webfont.eot');
  /* IE9 Compat Modes */
  src: url('/font/polor_regular-webfont.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('/font/polor_regular-webfont.woff') format('woff'), /* Pretty Modern Browsers */ url('/font/polor_regular-webfont.ttf') format('truetype');
  /* Safari, Android, iOS */
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: 'Polo';
  src: url('/font/polo_bold-webfont.eot');
  /* IE9 Compat Modes */
  src: url('/font/polo_bold-webfont.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('/font/polo_bold-webfont.woff') format('woff'), /* Pretty Modern Browsers */ url('/font/polo_bold-webfont.ttf') format('truetype');
  /* Safari, Android, iOS */
  font-style: normal;
  font-weight: bold;
}
@font-face {
  font-family: 'Polo';
  src: url('/font/polo_italic-webfont.eot');
  /* IE9 Compat Modes */
  src: url('/font/polo_italic-webfont.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('/font/polo_italic-webfont.woff') format('woff'), /* Pretty Modern Browsers */ url('/font/polo_italic-webfont.ttf') format('truetype');
  /* Safari, Android, iOS */
  font-style: italic;
  font-weight: normal;
}
@font-face {
  font-family: 'Polo';
  src: url('/font/polo_bolditalic-webfont.eot');
  /* IE9 Compat Modes */
  src: url('/font/polo_bolditalic-webfont.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('/font/polo_bolditalic-webfont.woff') format('woff'), /* Pretty Modern Browsers */ url('/font/polo_bolditalic-webfont.ttf') format('truetype');
  /* Safari, Android, iOS */
  font-style: italic;
  font-weight: bold;
}
@font-face {
  font-family: 'Polo Light';
  src: url('/font/polo22rleicht-webfont.eot');
  /* IE9 Compat Modes */
  src: url('/font/polo22rleicht-webfont.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('/font/polo22rleicht-webfont.woff') format('woff'), /* Pretty Modern Browsers */ url('/font/polo22rleicht-webfont.ttf') format('truetype');
  /* Safari, Android, iOS */
  font-style: normal;
  font-weight: normal;
}
/* TYPOGRAPHY */
html,
body {
  font-family: "Polo", sans-serif;
}
html p,
body p {
  padding: 0 0 1em 0;
  margin: 0;
}
html h1,
body h1,
html h2,
body h2,
html h3,
body h3,
html h4,
body h4,
html h5,
body h5,
html h6,
body h6 {
  margin: 0 0 0.5em 0;
  font-weight: normal;
  line-height: 1.2em;
}
html .redText,
body .redText {
  color: #f21c0a;
}
html h1,
body h1,
html .fsize-h1,
body .fsize-h1 {
  font-size: 2.62314rem;
  letter-spacing: -0.02em;
}
html h2,
body h2,
html .fsize-h2,
body .fsize-h2 {
  font-size: 1.7523rem;
  letter-spacing: -0.02em;
}
html h3,
body h3,
html .fsize-h3,
body .fsize-h3 {
  font-size: 1.34874rem;
  letter-spacing: -0.02em;
}
html h4,
body h4,
html .fsize-h4,
body .fsize-h4 {
  font-size: 1.34874rem;
  letter-spacing: -0.02em;
}
html h5,
body h5,
html .fsize-h5,
body .fsize-h5 {
  font-size: 1.062rem;
}
html a,
body a {
  cursor: pointer;
}
html a:focus,
body a:focus,
html a:hover,
body a:hover,
html a:active,
body a:active {
  outline: 0 none;
}
html .condensed,
body .condensed {
  letter-spacing: -1px;
}
html .underline,
body .underline {
  text-decoration: underline;
}
@media (max-width: 767px) {
  body {
    margin-bottom: 68px;
  }
  /* Responsive typography */
  html h1,
  body h1,
  html .fsize-h1,
  body .fsize-h1 {
    font-size: 2.124rem;
    letter-spacing: -0.02em;
  }
  html h2,
  body h2,
  html .fsize-h2,
  body .fsize-h2 {
    font-size: 1.7523rem;
    letter-spacing: -0.02em;
  }
  html h3,
  body h3,
  html .fsize-h3,
  body .fsize-h3 {
    font-size: 1.34874rem;
    letter-spacing: -0.02em;
  }
  html h4,
  body h4,
  html .fsize-h4,
  body .fsize-h4 {
    font-size: 1.34874rem;
    letter-spacing: -0.02em;
  }
}
/* LISTS */
ul,
ol {
  padding-left: 1.5em;
}
ul li,
ol li {
  margin-bottom: 1.062rem;
}
ul.tight li,
ol.tight li {
  margin-bottom: 0.531rem;
}
ul.extra-tight,
ol.extra-tight {
  padding-left: 1em;
}
ul.extra-tight li,
ol.extra-tight li {
  margin-bottom: 0em;
}
ul.arrowed,
ol.arrowed {
  list-style: none;
}
ul.arrowed li:before,
ol.arrowed li:before {
  /* use !important to prevent issues with browser extensions that change ../font */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e904";
  display: block;
  position: absolute;
  margin-left: -1.5em;
  color: #f21c0a;
}
ul.has-icons,
ol.has-icons {
  list-style: none;
}
ul.has-icons li > i,
ol.has-icons li > i {
  color: #f21c0a;
  position: absolute;
  margin-left: -1.5em;
}
ul.has-icons.has-icons-big,
ol.has-icons.has-icons-big {
  padding-left: 2em;
}
ul.has-icons.has-icons-big li > i,
ol.has-icons.has-icons-big li > i {
  font-size: 2em;
  margin-left: -1.2em;
}
ol.bordered-numbers {
  list-style: none;
  counter-reset: nm-counter;
}
ol.bordered-numbers li {
  counter-increment: nm-counter;
  margin-bottom: 1rem;
}
ol.bordered-numbers li::before {
  content: counter(nm-counter) ".";
  font-size: 1.3rem;
  margin-right: 1.5rem;
  padding: 0.2rem 0.5rem 0.2rem 0.7rem;
  border: 1px solid #000;
}
/* FILLER - EMPTY SPACE */
.filler {
  height: 3rem;
}
.filler-small {
  height: 1.5rem;
}
/* spat so sipkou */
.back-link {
  position: relative;
}
.back-link i {
  position: absolute;
  top: 1px;
}
.back-link span {
  padding-left: 1.3rem;
  text-decoration: underline;
}
@media (max-width: 767px) {
  .wrap-xs-2 {
    column-count: 2;
    column-gap: 45px;
  }
}
/* HR pod logom */
header hr {
  margin-bottom: 0;
}
.list-group a {
  text-decoration: none;
}
.list-group .list-group-item.active:before {
  display: block;
  content: '•';
  position: absolute;
  left: 7px;
}
@font-face {
  font-family: 'icomoon';
  src: url('/font/icomoon.eot?uq9l2b');
  src: url('/font/icomoon.eot?uq9l2b#iefix') format('embedded-opentype'), url('/font/icomoon.ttf?uq9l2b') format('truetype'), url('/font/icomoon.woff?uq9l2b') format('woff'), url('/font/icomoon.svg?uq9l2b#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}
.icon-instagram:before {
  content: "\e932";
}
.icon-arrow-down:before {
  content: "\e900";
}
.icon-arrow-down-2:before {
  content: "\e901";
}
.icon-arrow-left:before {
  content: "\e902";
}
.icon-arrow-left-2:before {
  content: "\e903";
}
.icon-arrow-right:before {
  content: "\e904";
}
.icon-arrow-right-2:before {
  content: "\e905";
}
.icon-arrow-up:before {
  content: "\e906";
}
.icon-arrow-up-2:before {
  content: "\e907";
}
.icon-chevron-thin-down:before {
  content: "\e908";
}
.icon-chevron-thin-left:before {
  content: "\e909";
}
.icon-chevron-thin-right:before {
  content: "\e90a";
}
.icon-chevron-thin-up:before {
  content: "\e90b";
}
.icon-elektrina:before {
  content: "\e90c";
}
.icon-elektro-doplnky:before {
  content: "\e90d";
}
.icon-euro:before {
  content: "\e90e";
}
.icon-facebook:before {
  content: "\e90f";
}
.icon-fin-z-uspor:before {
  content: "\e910";
}
.icon-helios-fot:before {
  content: "\e911";
}
.icon-helios-sol:before {
  content: "\e912";
}
.icon-klima:before {
  content: "\e913";
}
.icon-linkedin:before {
  content: "\e914";
}
.icon-menu:before {
  content: "\e915";
}
.icon-phone:before {
  content: "\e916";
}
.icon-plyn:before {
  content: "\e917";
}
.icon-setrisk:before {
  content: "\e918";
}
.icon-setrisk1:before {
  content: "\e919";
}
.icon-signal3:before {
  content: "\e91a";
}
.icon-triangle-down:before {
  content: "\e91b";
}
.icon-triangle-down-2:before {
  content: "\e91c";
}
.icon-triangle-left:before {
  content: "\e91d";
}
.icon-triangle-left-2:before {
  content: "\e91e";
}
.icon-triangle-right:before {
  content: "\e91f";
}
.icon-triangle-right-2:before {
  content: "\e920";
}
.icon-triangle-up:before {
  content: "\e921";
}
.icon-triangle-up-2:before {
  content: "\e922";
}
.icon-twitter:before {
  content: "\e923";
}
.icon-user:before {
  content: "\e924";
}
.icon-user-add:before {
  content: "\e925";
}
.icon-uspora-vody:before {
  content: "\e926";
}
.icon-usporne-osvetlenie-1:before {
  content: "\e927";
}
.icon-usporne-osvetlenie-2:before {
  content: "\e928";
}
.icon-youtube:before {
  content: "\e929";
}
.icon-zmena-adresy:before {
  content: "\e92a";
}
.icon-zmena-preddavkov:before {
  content: "\e92b";
}
.icon-zmena-sadzby-ee:before {
  content: "\e92c";
}
.icon-zmena-sadzby-plyn:before {
  content: "\e92d";
}
.icon-zse-smart-domov:before {
  content: "\e92e";
}
.icon-zse-zdravie:before {
  content: "\e92f";
}
.icon-lupa:before {
  content: "\e930";
}
.icon-nakupny-kosik:before {
  content: "\e931";
}
@font-face {
  font-family: 'icomoon-old';
  src: url('/font/icomoon2.eot?3f11rl');
  src: url('/font/icomoon2.eot?3f11rl#iefix') format('embedded-opentype'), url('/font/icomoon2.ttf?3f11rl') format('truetype'), url('/font/icomoon2.woff?3f11rl') format('woff'), url('/font/icomoon2.svg?3f11rl#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}
.ic-old-facebook:before {
  content: '\e81f';
}
/* '' */
.ic-old-search:before {
  content: '\e820';
}
/* '' */
.ic-old-encert:before {
  content: '\e821';
}
/* '' */
.ic-old-fire:before {
  content: '\e822';
}
/* '' */
.ic-old-poistenie:before {
  content: '\e823';
}
/* '' */
.ic-old-revizie-plyn:before {
  content: '\e824';
}
/* '' */
.ic-old-setrisk:before {
  content: '\e825';
}
/* '' */
.ic-old-phone:before {
  content: '\e827';
}
/* '' */
.ic-old-zse-asistuje:before {
  content: '\e60a';
}
/* '' */
.ic-old-youtube:before {
  content: '\e828';
}
/* '' */
.ic-old-zse-klub:before {
  content: '\e829';
}
/* '' */
.ic-old-zse-profiservis:before {
  content: '\e82a';
}
/* '' */
.ic-old-zse-zdravie:before {
  content: '\e82b';
}
/* '' */
.ic-old-flash:before {
  content: '\e82c';
}
/* '' */
.ic-old-none:before {
  content: "\ea59";
}
.ic-old-alert:before {
  content: '\ea59';
}
/* '' */
.ic-old-angle-left:before {
  content: '\ea59';
}
/* '' */
.ic-old-angle-right:before {
  content: '\ea59';
}
/* '' */
.ic-old-close:before {
  content: '\ea59';
}
/* '' */
.ic-old-down-dir:before {
  content: '\ea59';
}
/* '' */
.ic-old-down:before {
  content: '\ea59';
}
/* '' */
.ic-old-euro:before {
  content: '\ea59';
}
/* '' */
.ic-old-gplus:before {
  content: '\ea59';
}
/* '' */
.ic-old-info:before {
  content: '\ea59';
}
/* '' */
.ic-old-left-dir:before {
  content: '\ea59';
}
/* '' */
.ic-old-left-open:before {
  content: '\ea59';
}
/* '' */
.ic-old-location:before {
  content: '\ea59';
}
/* '' */
.ic-old-mail:before {
  content: '\ea59';
}
/* '' */
.ic-old-menu:before {
  content: '\ea59';
}
/* '' */
.ic-old-money:before {
  content: '\ea59';
}
/* '' */
.ic-old-ok:before {
  content: '\ea59';
}
/* '' */
.ic-old-right-dir:before {
  content: '\ea59';
}
/* '' */
.ic-old-right-open-mini:before {
  content: '\ea59';
}
/* '' */
.ic-old-right-open:before {
  content: '\ea59';
}
/* '' */
.ic-old-shuffle:before,
.ic-old-exchange:before {
  content: '\ea59';
}
/* '' */
.ic-old-up:before {
  content: '\ea59';
}
/* '' */
.ic-old-user-add:before {
  content: '\ea59';
}
/* '' */
.ic-old-user:before {
  content: '\ea59';
}
/* '' */
.ic-old-warning:before {
  content: '\ea59';
}
/* '' */
.ic-old-wrench-1:before {
  content: '\ea59';
}
/* '' */
.ic-old-down:before,
.ic-old-down-dir:before {
  content: "\e608";
}
.ic-old-left:before,
.ic-old-angle-left:before,
.ic-old-left-dir:before,
.ic-old-left-open:before {
  content: "\e609";
}
.ic-old-right:before,
.ic-old-angle-right:before,
.ic-old-right-dir:before,
.ic-old-right-open-mini:before,
.ic-old-right-open:before {
  content: "\e60b";
}
.ic-old-home:before {
  content: "\e611";
}
.ic-old-office:before {
  content: "\e612";
}
.ic-old-pencil:before {
  content: "\e616";
}
.ic-old-droplet:before {
  content: "\e617";
}
.ic-old-film:before {
  content: "\e61a";
}
.ic-old-lifebuoy:before {
  content: "\e61f";
}
.ic-old-envelop:before {
  content: "\e620";
}
.ic-old-alarm:before {
  content: "\e621";
}
.ic-old-bubble2:before {
  content: "\e625";
}
.ic-old-binoculars:before {
  content: "\e627";
}
.ic-old-wrench:before {
  content: "\e629";
}
.ic-old-hammer:before {
  content: "\e62a";
}
.ic-old-gift:before {
  content: "\e62c";
}
.ic-old-leaf:before {
  content: "\e62d";
}
.ic-old-fire2:before {
  content: "\e62e";
}
.ic-old-truck:before {
  content: "\e62f";
}
.ic-old-power:before {
  content: "\e630";
}
.ic-old-power-cord:before {
  content: "\e631";
}
.ic-old-notification:before {
  content: "\e635";
}
.ic-old-plus:before {
  content: "\e636";
}
.ic-old-minus:before {
  content: "\e637";
}
.ic-old-info2:before {
  content: "\e638";
}
.ic-old-checkmark:before {
  content: "\e639";
}
.ic-old-checkmark2:before {
  content: "\e63a";
}
.ic-old-twitter:before {
  content: "\e63c";
}
.ic-old-feed2:before {
  content: "\e63d";
}
.ic-old-vimeo:before {
  content: "\e63e";
}
.ic-old-tumblr:before {
  content: "\e63f";
}
.ic-old-skype:before {
  content: "\e644";
}
.ic-old-linkedin:before {
  content: "\e645";
}
.ic-old-file-pdf:before {
  content: "\e646";
}
.ic-old-file-word:before {
  content: "\e647";
}
.ic-old-mail:before {
  content: "\e610";
}
.ic-old-zmena-adresy:before {
  content: "\e60d";
}
.ic-old-close:before {
  content: "\e800";
}
.ic-old-down:before {
  content: "\e608";
}
.ic-old-download:before {
  content: "\e600";
}
.ic-old-ecert:before {
  content: "\e821";
}
.ic-old-efaktura:before {
  content: "\e601";
}
.ic-old-euro:before {
  content: "\e602";
}
.ic-old-facebook:before {
  content: "\e81f";
}
.ic-old-fire:before {
  content: "\e822";
}
.ic-old-flash:before {
  content: "\e82c";
}
.ic-old-gplus:before {
  content: "\e603";
}
.ic-old-left:before {
  content: "\e609";
}
.ic-old-location:before {
  content: "\e606";
}
.ic-old-menu:before {
  content: "\e604";
}
.ic-old-nastavenie:before {
  content: "\e605";
}
.ic-old-none:before {
  content: "\ea59";
}
.ic-old-phone:before {
  content: "\e827";
}
.ic-old-poistenie:before {
  content: "\e823";
}
.ic-old-revizie-plyn:before {
  content: "\e824";
}
.ic-old-right:before {
  content: "\e60b";
}
.ic-old-search:before {
  content: "\e820";
}
.ic-old-setrisk:before {
  content: "\e825";
}
.ic-old-up:before {
  content: "\e60c";
}
.ic-old-user:before {
  content: "\e607";
}
.ic-old-youtube:before {
  content: "\e828";
}
.ic-old-ziadosti:before {
  content: "\e60e";
}
.ic-old-zse-asistuje:before {
  content: "\e60a";
}
.ic-old-zse-klub:before {
  content: "\e829";
}
.ic-old-zse-profiservis:before {
  content: "\e82a";
}
.ic-old-zse-zdravie:before {
  content: "\e82b";
}
.ic-old-show:before {
  content: "\e9ce";
}
.ic-old-hide:before {
  content: "\e9d1";
}
.ic-old-user-add:before {
  content: "\e60f";
}
.ic-old-zmena-odberatela:before {
  content: "\e614";
}
.ic-old-zmena-preddavkov:before {
  content: "\e615";
}
.ic-old-check:before {
  content: "\f00c";
}
.ic-old-info:before {
  content: "\f129";
}
.ic-old-alert:before {
  content: "\f12a";
}
.ic-old-asterisk:before {
  content: "\e613";
}
.ic-old-elektromob:before {
  content: "\e613";
}
.ic-old-eneraudit:before {
  content: "\e618";
}
.ic-old-eneropt:before {
  content: "\e619";
}
.ic-old-eservis:before {
  content: "\e61b";
}
.ic-old-fin-z-uspor:before {
  content: "\e61c";
}
.ic-old-mod-osvetlenia:before {
  content: "\e61d";
}
.ic-old-balik-domov:before {
  content: "\e900";
}
.ic-old-it-pomoc:before {
  content: "\e901";
}
.ic-old-helios:before {
  content: "\e902";
}
.ic-old-heart-empty:before {
  content: "\e906";
}
.ic-old-heart-full:before,
.ic-old-heart:before {
  content: "\e903";
}
.ic-old-heart-minus:before {
  content: "\e904";
}
.ic-old-heart-plus:before {
  content: "\e905";
}
/* skupina-zse */
@font-face {
  font-family: 'icomoon-skupina';
  src: url('/font/icomoon-skupina.eot?jqhdh3');
  src: url('/font/icomoon-skupina.eot?jqhdh3#iefix') format('embedded-opentype'), url('/font/icomoon-skupina.woff2?jqhdh3') format('woff2'), url('/font/icomoon-skupina.ttf?jqhdh3') format('truetype'), url('/font/icomoon-skupina.woff?jqhdh3') format('woff'), url('/font/icomoon-skupina.svg?jqhdh3#icomoon-skupina') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^="icon-skupina-"],
[class*=" icon-skupina-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon-skupina' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-skupina-esg-ratingy:before {
  content: "\e91f";
  color: #f21c0a;
}
.icon-skupina-esg-politiky:before {
  content: "\e920";
  color: #f21c0a;
}
.icon-skupina-sto-rokov-bold:before {
  content: "\e91d";
  color: #f21c0a;
}
.icon-skupina-sto-rokov:before {
  content: "\e91e";
  color: #f21c0a;
}
.icon-skupina-ocenenia-zamestnavatela:before {
  content: "\e91a";
  color: #f21c0a;
}
.icon-skupina-reporty-udrzatelnosti:before {
  content: "\e91b";
  color: #f21c0a;
}
.icon-skupina-uhlikove-audity:before {
  content: "\e91c";
  color: #f21c0a;
}
.icon-skupina-zelena-zima:before {
  content: "\e919";
  color: #92ba52;
}
.icon-skupina-zelene-leto:before {
  content: "\e918";
  color: #b0bb5a;
}
.icon-skupina-zse-zabava-a-sport:before {
  content: "\e917";
  color: #f21c0a;
}
.icon-skupina-zse-aktuality:before {
  content: "\e913";
  color: #f21c0a;
}
.icon-skupina-zse-fotografie:before {
  content: "\e914";
  color: #f21c0a;
}
.icon-skupina-zse-kontakt-media:before {
  content: "\e915";
  color: #f21c0a;
}
.icon-skupina-zse-videa:before {
  content: "\e916";
  color: #f21c0a;
}
.icon-skupina-zse-3ec-certifikat:before {
  content: "\e911";
  color: #f21c0a;
}
.icon-skupina-zse-nehnutelnosti-na-predaj:before {
  content: "\e912";
  color: #f21c0a;
}
.icon-skupina-zse-dcerske-spolocnosti1:before {
  content: "\e90b";
  color: #f21c0a;
}
.icon-skupina-zse-etika-transparentnost1:before {
  content: "\e90c";
  color: #f21c0a;
}
.icon-skupina-zse-historia-spolocnosti1:before {
  content: "\e90d";
  color: #f21c0a;
}
.icon-skupina-zse-predstavenstvo1:before {
  content: "\e90e";
  color: #f21c0a;
}
.icon-skupina-zse-prehlad-akcionarov1:before {
  content: "\e90f";
  color: #f21c0a;
}
.icon-skupina-zse-vyrocne-spravy1:before {
  content: "\e910";
  color: #f21c0a;
}
.icon-skupina-benefity:before {
  content: "\e906";
  color: #f21c0a;
}
.icon-skupina-preco-pracovat-v-zse:before {
  content: "\e907";
  color: #f21c0a;
}
.icon-skupina-pre-studentov-absolventov:before {
  content: "\e908";
  color: #f21c0a;
}
.icon-skupina-pre-zamestnancov:before {
  content: "\e909";
  color: #f21c0a;
}
.icon-skupina-volne-pracovne-miesta:before {
  content: "\e90a";
  color: #f21c0a;
}
.icon-skupina-zse-dcerske-spolocnosti:before {
  content: "\e900";
  color: #f21c0a;
}
.icon-skupina-zse-etika-transparentnost:before {
  content: "\e901";
  color: #f21c0a;
}
.icon-skupina-zse-historia-spolocnosti:before {
  content: "\e902";
  color: #f21c0a;
}
.icon-skupina-zse-predstavenstvo:before {
  content: "\e903";
  color: #f21c0a;
}
.icon-skupina-zse-prehlad-akcionarov:before {
  content: "\e904";
  color: #f21c0a;
}
.icon-skupina-zse-vyrocne-spravy:before {
  content: "\e905";
  color: #f21c0a;
}
/* FORM INCLUDE */
/* FORM INPUTS */
/* BUTTONS */
a.arrowed-left {
  display: inline-block;
  padding-left: 1.5em;
}
a.arrowed-left:before {
  /* use !important to prevent issues with browser extensions that change ../font */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e904";
  display: block;
  position: absolute;
  margin-left: -1.5em;
  color: #f21c0a;
  text-decoration: underline;
}
a.arrowed-left:before {
  text-decoration: none;
}
/* Override */
a.btn {
  text-decoration: underline;
}
a.btn.no-underline {
  text-decoration: none;
}
a.btn.arrowed {
  position: relative;
  padding-right: 2rem;
  text-decoration: none;
}
a.btn.arrowed:after {
  /* use !important to prevent issues with browser extensions that change ../font */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e904";
  display: block;
  position: absolute;
  right: 6px;
  top: 6px;
  color: #FFF;
}
a.btn.arrowed.btn-secondary:after {
  color: #212529;
}
a.btn.arrowed-back {
  position: relative;
  padding-left: 2rem;
  text-decoration: none;
}
a.btn.arrowed-back:after {
  /* use !important to prevent issues with browser extensions that change ../font */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e902";
  display: block;
  position: absolute;
  left: 6px;
  top: 6px;
  color: #FFF;
}
.btn.btn-outline-secondary {
  color: #000;
  background-color: transparent;
  border-color: #000;
}
.btn.btn-outline-secondary:hover {
  color: #000;
  background-color: transparent;
  border-color: #000;
}
.btn.btn-light {
  color: #000;
  background-color: #FFF;
  border-color: #bfbfbf;
}
.btn.btn-light:hover {
  color: #000;
  background-color: #FFF;
  border-color: #bfbfbf;
}
.btn.btn-light i {
  color: #f21c0a;
}
.btn.btn-link,
.btn.btn-outline-secondary {
  text-decoration: none !important;
}
.btn.btn-link.arrowed:after,
.btn.btn-outline-secondary.arrowed:after {
  color: #f21c0a;
}
.btn.btn-link i,
.btn.btn-outline-secondary i {
  color: #f21c0a;
}
.btn.btn-link {
  color: #f21c0a;
}
.btn.btn-link:hover {
  color: #000;
}
.btn.btn-block {
  text-align: left;
}
.btn.btn-block i {
  float: right;
  color: #000;
}
.btn.btn-primary i {
  color: #FFF;
}
.btn.btn-icon-left {
  padding-left: 1.5em;
  position: relative;
}
.btn.btn-icon-left i {
  position: absolute;
  left: 0.58823529rem;
}
.btn.btn-icon-left.btn-link i {
  left: 0;
}
.btn.btn-icon-right {
  padding-right: 2em;
  position: relative;
}
.btn.btn-icon-right i {
  position: absolute;
  right: 0.58823529rem;
}
.btn.btn-icon-right.btn-link i {
  right: 0;
}
.claim-link {
  display: block;
  text-decoration: none;
  color: #f21c0a;
  padding-left: 0.5em;
}
.claim-link i {
  position: absolute;
  top: 0.1em;
  left: -0.5em;
}
/* PAGES INCLUDE */
/* kontakty - riadky s borderom */
.row-bordered {
  border: 1px solid #bfbfbf;
  border-top: 0;
  padding: 1em;
}
.row-bordered.row-bordered:first-of-type {
  border: 1px solid #bfbfbf;
}
.row-bordered .text-align {
  display: flex;
  align-items: center;
}
.row-bordered .heading {
  color: #f21c0a;
  display: flex;
  align-items: center;
}
.row-bordered .heading a {
  vertical-align: middle;
  text-decoration: none;
  color: #f21c0a;
}
.row-bordered .heading i {
  margin-right: 1rem;
  vertical-align: middle;
  color: #f21c0a;
  font-size: 2.62314rem;
}
@media (max-width: 767px) {
  .row-bordered .heading {
    margin-bottom: 1rem;
  }
}
.row-bordered .info-text {
  color: #666;
}
/* kontakty - detail */
.div-bordered {
  border: 1px solid #bfbfbf;
}
.tabs-buttons {
  position: relative;
}
.tabs-buttons .clicked {
  position: relative;
  border: 1px solid #bfbfbf;
  border-bottom: 1px solid #FFF;
  z-index: 2;
}
.tabs-buttons .clicked:hover {
  border-bottom-color: transparent;
}
.tabs-buttons.shown:after {
  position: absolute;
  content: "";
  width: 100%;
  bottom: 0;
  left: 0;
  border-bottom: 1px solid #bfbfbf;
  z-index: 1;
}
.pobocky-header-top {
  padding: 1em 1.25em;
  border-bottom: 1px solid #bfbfbf;
}
.pobocky-bottom-wrap {
  display: flex;
  flex-wrap: wrap;
}
.pobocky-bottom-wrap .pobocky-left-nav {
  height: auto;
  max-width: 270px;
  /*display: inline-block;*/
  flex: 1 0 auto;
  border-right: 1px solid #bfbfbf;
}
.pobocky-bottom-wrap .pobocky-left-nav .pobocky-nav-item {
  min-height: 60px;
  border-bottom: 1px solid #bfbfbf;
  padding: 0.5em 1.25em;
}
.pobocky-bottom-wrap .pobocky-left-nav .pobocky-nav-item .mesto {
  color: #f21c0a;
  position: relative;
}
.pobocky-bottom-wrap .pobocky-left-nav .pobocky-nav-item .mesto i {
  position: absolute;
  top: 1px;
  left: -3px;
}
.pobocky-bottom-wrap .pobocky-left-nav .pobocky-nav-item .mesto span {
  padding-left: 1rem;
}
.pobocky-bottom-wrap .pobocky-left-nav .pobocky-nav-item .ulica {
  color: #4d4d4d;
  padding-left: 1rem;
}
.pobocky-bottom-wrap .pobocky-right-detail {
  /*
		display: inline-block;
		width: calc(~"100% - 270px");
		*/
  flex: 1 0 auto;
  display: flex;
  flex-wrap: wrap;
}
.pobocky-bottom-wrap .pobocky-right-detail .pobocky-detail-empty {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4d4d4d;
  min-height: 200px;
}
.pobocky-bottom-wrap .pobocky-right-detail .pobocky-detail-left {
  flex: 1 0 auto;
  padding: 1em 1em;
}
.pobocky-bottom-wrap .pobocky-right-detail .pobocky-detail-left table.otvaracie-hodiny {
  width: 100%;
}
.pobocky-bottom-wrap .pobocky-right-detail .pobocky-detail-left table.otvaracie-hodiny tr td:nth-of-type(1) {
  min-width: 25%;
}
.pobocky-bottom-wrap .pobocky-right-detail .pobocky-detail-left table.otvaracie-hodiny tr td:nth-of-type(2) {
  min-width: 40%;
}
.pobocky-bottom-wrap .pobocky-right-detail .pobocky-detail-left table.otvaracie-hodiny tr td:nth-of-type(3) {
  min-width: 30%;
}
.pobocky-bottom-wrap .pobocky-right-detail .pobocky-detail-right {
  flex: 1 0 auto;
  max-width: 265px;
  background-color: #f1f1f1;
  padding: 0.8em;
}
.pobocky-bottom-wrap .pobocky-right-detail .pobocky-detail-right img {
  width: 240px;
}
.pobocky-bottom-wrap .pobocky-right-detail .pobocky-detail-right .zodpovedna-osoba {
  margin-top: 2em;
}
.pobocky-bottom-wrap .pobocky-right-detail .pobocky-detail-right .zodpovedna-osoba img {
  width: 68px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 7px;
}
.pobocky-bottom-wrap .pobocky-right-detail .pobocky-detail-right .zodpovedna-osoba span {
  display: inline-block;
  vertical-align: middle;
}
.job-offers h1 {
  margin-bottom: 0.5rem;
}
.job-offers .subtitle {
  font-size: 1.6rem;
}
.job-offers .job-info-item {
  margin-bottom: 1rem;
}
.job-offers .job-info-item i {
  vertical-align: top;
  display: inline-block;
  width: 1.5rem;
  font-size: 1.5rem;
  text-align: center;
  color: #f21c0a;
}
.job-offers .job-info-item .inline-block {
  display: inline-block;
  margin-left: 0.5rem;
  max-width: calc(100% - 3rem);
}
.job-offers .job-info-item .inline-block .item-value {
  color: #4d4d4d;
  font-size: 1rem;
}
.job-offers .list-square {
  list-style-type: square;
}
main.iframe-use {
  max-width: 100%;
}
main.iframe-use .job-offers-list {
  color: #F0654D;
}
main.iframe-use .job-offers-list > .row > .offset-md-1 {
  margin-left: 0;
}
main.iframe-use .job-offers-list > .row > .col-md-6 {
  flex: 0 0 100%;
  max-width: 100%;
}
main.iframe-use .job-offers-list .red {
  color: #F0654D;
}
main.iframe-use .job-offers-list h1.main-headline {
  display: none;
}
main.iframe-use .job-offers-list .accordion .card .card-header.with-icon.arrow::after {
  color: #F0654D;
}
main.iframe-use .job-offers .video-preview {
  display: none;
}
@media (max-width: 1000px) {
  main.iframe-use .job-offers .back-arrow {
    display: block;
  }
}
.job-offers-list .job-offers-list-item {
  margin-top: 1rem;
  border: 1px solid #bfbfbf !important;
}
.job-offers-list .job-offers-list-item .company-logo img {
  max-width: 100%;
  height: auto;
}
@media (max-width: 576px) {
  .job-offers-list .job-offers-list-item .company-logo {
    margin-top: 1rem;
    margin-bottom: 1.5rem;
  }
}
.job-offers-list .job-offers-list-item .location {
  font-size: 1.2rem;
}
.job-offers-swiper .swiper-slide img {
  display: block;
  width: 100%;
  height: auto;
}
.back-arrow {
  font-size: 2rem;
  text-align: right;
  height: 100%;
  color: #f21c0a;
}
.back-arrow i {
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  color: #f21c0a;
}
@media (max-width: 1000px) {
  .back-arrow {
    display: none;
  }
}
.job-form .errorfield {
  border: 1px solid #f21c0a;
  color: #f21c0a;
}
.job-form .required {
  color: #f21c0a;
}
.job-form .driving-licenses label {
  margin-right: 1rem;
}
.job-form .upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}
.job-form .upload-btn-wrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
.jobCount-wrapper {
  display: inline-flex;
}
.jobCount-wrapper .jobCount {
  background-color: #f21c0a;
  font-size: 14px;
  margin-left: 8px;
  border-radius: 50%;
  width: 26px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}
.q-group {
  margin-top: 2.5rem;
}
.question {
  display: block;
  font-size: 1.34874rem;
  margin-bottom: 0.5rem;
}
.answer {
  width: 100%;
  max-width: 550px;
  height: 30px;
  line-height: 30px;
  border: 0;
  border-bottom: 1px solid #cbcbcb;
  padding-right: 0.5rem;
  font-size: 1rem;
  font-weight: normal;
}
textarea.answer {
  border: 1px solid #cbcbcb;
  min-height: 150px;
}
.answer-short {
  max-width: 300px;
}
label.radiob-label {
  margin-left: 0.5rem;
  font-size: 1rem;
}
.radiob-div {
  margin-top: 0.5rem;
}
label.error {
  color: #f21c0a;
}
input.error {
  color: #f21c0a;
  border-bottom: 1px solid rgba(242, 28, 10, 0.5);
}
.required {
  color: #f21c0a;
  font-family: Arial;
}
.errorTextDiv {
  color: #f21c0a;
  height: 30px;
  line-height: 30px;
  font-size: 1rem;
}
.file-upload-container .file-upload-info {
  line-height: 2rem;
  font-size: 1rem;
  margin-bottom: 0.5rem;
}
.file-upload-container .file-upload-info i.icon-skupina-zse-aktuality::before {
  color: black;
}
.file-upload-container .file-upload-info .delete-file {
  display: inline-block;
  margin-left: 1rem;
}
.file-upload-container .file-upload-info .upload-progress-container {
  position: relative;
}
.file-upload-container .file-upload-info .upload-progress-container .upload-progress {
  display: none;
  height: 15px;
  border-left: 1px solid black;
  border-right: 1px solid black;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 150px;
  margin-left: 20px;
  margin-right: 10px;
}
.file-upload-container .file-upload-info .upload-progress-container .upload-progress .upload-progress-bar {
  height: 100%;
  background-color: black;
  width: 0px;
}
.file-upload-container .file-upload-info .upload-progress-container .upload-percentage {
  display: inline-block;
  position: absolute;
  top: 9px;
  font-size: 0.8rem;
  line-height: 0.8rem;
}
.file-upload-container .file-upload-info .file-upload-error {
  color: #f21c0a;
}
#searchResult .searchResult {
  margin-bottom: 2rem;
}
#searchResult .searchResult a {
  color: #f21c0a;
  text-decoration: none;
  font-size: 1.2rem;
}
#searchResult .searchResult span {
  font-size: 0.9rem;
}
#searchResult .searchResult span .luceneHit {
  font-weight: bold;
}
.newsList .list {
  margin-bottom: 3rem;
}
.newsList .list .news-image {
  display: block;
  height: 170px;
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;
}
.newsList .list .date_time {
  font-size: 0.9rem;
  margin-bottom: 0.1rem;
}
.newsList .list .heading a {
  text-decoration: none;
  color: #f21c0a;
  font-size: 1.6rem;
}
@media (max-width: 990px) {
  .newsList .list .news-image {
    margin-bottom: 0.6rem;
  }
  .newsList .list .heading {
    margin-bottom: 0.3rem;
  }
}
.newsDetail .heading {
  font-size: 2.62314rem;
  text-align: center;
  margin-bottom: 0.5rem;
}
.newsDetail .date {
  text-align: center;
}
.newsDetail .article-image {
  display: block;
  margin-top: 2rem;
}
.newsDetail .article-image img {
  width: 100%;
  height: auto;
}
.newsDetail .article-content {
  margin-top: 2rem;
}
.oslavujeme .heading-container {
  margin-top: 40px;
  padding: 0 90px;
}
.oslavujeme .heading-container h1 {
  color: #f21c0a;
  font-size: 95px;
  margin-bottom: 0;
}
.oslavujeme .heading-container h2 {
  margin-top: 0;
  color: #f21c0a;
}
.oslavujeme section.intro {
  margin-top: 50px;
  padding-left: 7%;
}
.oslavujeme section.intro .container {
  max-width: unset;
  border-left: 2px solid #f21c0a;
  border-bottom: 2px solid #f21c0a;
  padding-top: 0;
  padding-left: 120px;
  padding-bottom: 50px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 35px;
  position: relative;
}
.oslavujeme section.intro .container .start-icon {
  position: absolute;
  top: 15%;
  left: 0;
  width: 79px;
  transform: translate(-50%, -50%);
}
.oslavujeme section.intro .container .start-icon img {
  display: block;
}
.oslavujeme section.intro .container p {
  margin-top: 25px;
  padding: 0;
  font-size: 24px;
  line-height: 36px;
  max-width: 500px;
}
.oslavujeme section.scene {
  width: 100%;
  padding-bottom: 150px;
  position: relative;
  display: grid;
  grid-template-areas: "content scrollbar-place";
  grid-template-columns: 1fr 2px;
}
.oslavujeme section.scene .scrollmagic-pin-spacer {
  box-sizing: border-box;
  align-content: start;
}
.oslavujeme section.scene .content-container {
  min-height: 100vh;
  display: grid;
}
.oslavujeme section.scene .content-container .content {
  min-height: 100vh;
  width: 100%;
  display: grid;
  grid-template-columns: 70% 30%;
  align-items: start;
  padding: 100px;
  padding-top: 150px;
  padding-right: 100px;
  transition: opacity 250ms ease;
}
.oslavujeme section.scene .content-container .content .static-image {
  max-width: 740px;
  max-height: 450px;
  aspect-ratio: 1000 / 565;
  perspective: 1000px;
}
.oslavujeme section.scene .content-container .content .static-image img {
  grid-area: images;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 30px;
  transform: rotate3d(0.1, 1, 0, 20deg) translate3d(50px, -50px, 0);
  opacity: 1;
  z-index: 1;
}
@supports (background: -webkit-named-image(i)) {
  .oslavujeme section.scene .content-container .content .static-image img {
    transform: unset;
  }
}
.oslavujeme section.scene .content-container .content .images {
  height: auto;
  width: auto;
  max-width: 740px;
  max-height: 450px;
  aspect-ratio: 1000 / 565;
  display: grid;
  grid-template-areas: "images";
  perspective: 1000px;
  /*
                    &.change {
                        img:first-child {
                            animation: card-in 1s;
                            animation-direction: normal;
                            animation-fill-mode: forwards;
                            animation-direction: normal;
                        }

                        img:last-child {
                            animation: card-out 1s;
                            animation-direction: normal;
                            animation-fill-mode: forwards;
                            animation-direction: normal;
                        }
                    }

                    @keyframes card-in {
                        0% {
                            transform: rotate3d(0.1, 1, 0, 20deg);
                            opacity: 0.4;
                            z-index: 0;
                        }
                        100% {
                            transform: rotate3d(0.1, 1, 0, 20deg) translate3d(50px, -50px , 0);
                            opacity: 1;
                            z-index: 1;
                        }
                    }

                    @keyframes card-out {
                        0% {
                            transform: rotate3d(0.1, 1, 0, 20deg) translate3d(50px, -50px , 0);
                            opacity: 1;
                        }
                        50%  {
                            transform: rotate3d(0, 0.6, 2.6, 74deg) translate3d(50px, -50px, 0) scale(0.7);
                            opacity: 1;
                            transform-origin: bottom right;
                        }
                        100% {
                            transform: rotate3d(0.1, 1, 0, 20deg);
                            opacity: 0.4;
                            z-index: 0;
                        }
                    }
                    */
}
.oslavujeme section.scene .content-container .content .images img {
  grid-area: images;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 30px;
}
.oslavujeme section.scene .content-container .content .images img:first-child {
  transform: rotate3d(0.1, 1, 0, 20deg);
  opacity: 0.4;
  z-index: 0;
}
@supports (background: -webkit-named-image(i)) {
  .oslavujeme section.scene .content-container .content .images img:first-child {
    transform: unset;
  }
}
.oslavujeme section.scene .content-container .content .images img:last-child {
  transform: rotate3d(0.1, 1, 0, 20deg) translate3d(50px, -50px, 0);
  opacity: 1;
  z-index: 1;
}
@supports (background: -webkit-named-image(i)) {
  .oslavujeme section.scene .content-container .content .images img:last-child {
    transform: unset;
  }
}
.oslavujeme section.scene .content-container .content .text {
  margin-left: 50px;
  display: grid;
}
.oslavujeme section.scene .content-container .content .text > div {
  grid-area: 1 / 2;
  opacity: 0;
  transition: opacity 2000ms ease;
}
.oslavujeme section.scene .content-container .content .text > div.visible {
  opacity: 1;
  z-index: 1;
}
.oslavujeme section.scene .content-container .content .text p {
  margin-top: 25px;
  padding: 0;
  font-size: 20px;
  line-height: 29px;
  max-width: 300px;
}
.oslavujeme section.scene .scrollbar {
  height: calc(100vh - 100px);
  grid-area: scrollbar-place;
  background-color: #f21c0a;
  position: sticky;
  top: 0;
}
.oslavujeme section.scene .scrollbar .event {
  display: grid;
  grid-template-columns: auto 40px;
  position: absolute;
  right: 0;
  transform: translateX(19px) translateY(50%);
}
.oslavujeme section.scene .scrollbar .event.start {
  display: none;
  grid-template-columns: auto 76px;
  top: 50px;
  transform: translateX(36.5px) translateY(50%);
  z-index: 2;
}
.oslavujeme section.scene .scrollbar .event.end {
  grid-template-columns: auto 76px;
  bottom: 0;
  transform: translateX(36.5px) translateY(50%);
}
.oslavujeme section.scene .scrollbar .event.current {
  top: 100px;
}
.oslavujeme section.scene .scrollbar .event .text {
  color: #f21c0a;
  font-size: 20px;
  line-height: 27px;
  padding-right: 20px;
  display: grid;
  align-items: center;
}
.oslavujeme section.scene .scrollbar .event .text span {
  grid-area: 1 / 2;
  opacity: 0;
  transition: opacity 250ms ease;
}
.oslavujeme section.scene .scrollbar .event .text span.active {
  opacity: 1;
}
.oslavujeme section.scene .scrollbar .event .icon img {
  display: block;
}
@media screen and (min-width: 768) and (max-width: 1300px) {
  .oslavujeme section.scene .content-container .content .images {
    padding-left: 50px;
  }
  .oslavujeme section.scene .content-container .content .text {
    padding-left: 50px;
  }
}
@media screen and (max-width: 1300px) {
  .oslavujeme section {
    padding-left: 40px;
    padding-right: 40px;
  }
  .oslavujeme section .heading-container {
    padding: 0;
  }
  .oslavujeme section .heading-container h1 {
    font-size: 50px;
  }
  .oslavujeme section .heading-container h2 {
    font-size: 20px;
  }
  .oslavujeme section.intro {
    margin-top: 50px;
    padding-left: 50px;
  }
  .oslavujeme section.intro .container {
    padding-left: 15%;
    padding-bottom: 60px;
    grid-template-columns: auto;
    gap: 0;
  }
  .oslavujeme section.intro .container p {
    font-size: 18px;
    line-height: 29px;
  }
  .oslavujeme section.scene {
    padding-bottom: 50px;
  }
  .oslavujeme section.scene .content-container {
    padding: 50px 0;
  }
  .oslavujeme section.scene .content-container .content {
    display: block;
    padding: 0;
  }
  .oslavujeme section.scene .content-container .content .static-image {
    padding-right: 50px;
  }
  .oslavujeme section.scene .content-container .content .static-image img {
    transform: rotate3d(0.1, 1, 0, 20deg);
  }
  @supports (background: -webkit-named-image(i)) {
    .oslavujeme section.scene .content-container .content .static-image img {
      transform: unset;
    }
  }
  .oslavujeme section.scene .content-container .content .images {
    box-sizing: content-box;
    margin-top: 50px;
    margin-right: 80px;
    max-width: 500px;
    max-height: 283px;
  }
  .oslavujeme section.scene .content-container .content .images img {
    width: 100%;
    height: 100%;
  }
  .oslavujeme section.scene .content-container .content .images img:last-child {
    transform: rotate3d(0.1, 1, 0, 20deg) translate3d(5%, -15%, 0);
  }
  @supports (background: -webkit-named-image(i)) {
    .oslavujeme section.scene .content-container .content .images img:last-child {
      transform: unset;
    }
  }
  .oslavujeme section.scene .content-container .content .text {
    padding-top: 30px;
    padding-right: 50px;
    margin-left: 0;
    justify-content: start;
  }
  .oslavujeme section.scene .content-container .content .text p {
    font-size: 18px;
    line-height: 29px;
    max-width: 500px;
  }
}
@media screen and (max-width: 359px) {
  .oslavujeme section.scene .content-container .content .text p {
    font-size: 13px;
    line-height: 17px;
  }
}
/* COMPONENTS INCLUDE */
/* CARDS */
/*.card-deck {
	&.card-deck-1 {
		.card {
			display: flex;

			max-width: 100%;
		}
	}

	&.card-deck-2 {
		.card {
			max-width: 50%;
		}
	}

	&.card-deck-3 {
		.card {
			max-width: 33.332%;
		}
	}

	&.card-deck-4 {
		.card {
			max-width: 24.998%;
		}
	}

	&.card-deck-6 {
		.card {
			max-width: 16.665%;
		}
	}

	&.card-deck-8 {
		.card {
			max-width: 12.499%;
		}
	}
}*/
/* cards fix pre tri stlpce */
@media (min-width: 768px) {
  .three-column.card-grid-md-2 .card-tile {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
}
@media (max-width: 767px) {
  .vertical-scroll {
    position: relative;
    padding-right: 0;
  }
  .vertical-scroll:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 3rem;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+100 */
    background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=1);
    /* IE6-9 */
  }
  .vertical-scroll .card-grid {
    flex-wrap: nowrap;
    overflow-x: scroll;
    margin-right: 1rem;
  }
  .vertical-scroll .card-grid .card-tile {
    min-width: 75%;
  }
}
.card.no-overflow {
  overflow: hidden;
}
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .card-img {
    height: 100%;
  }
}
/* Homepage - rozcestníkový prvok 3, pod carouselom  */
.card-homepage .card-header {
  padding: 0.5rem 0.0rem;
}
.card-homepage .card-inner-frame {
  border: 1px solid #cbcbcb;
  min-height: 13.2rem;
  position: relative;
}
.card-homepage .card-inner-frame .card-img-wrapper {
  height: 125px;
  width: 100%;
  overflow: hidden;
}
.card-homepage .card-inner-frame .card-img {
  object-fit: cover;
  height: 125px;
}
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .card-homepage .card-inner-frame .card-img {
    height: auto;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.card-homepage .card-inner-frame .card-title {
  margin-top: 0.7rem;
  margin-left: 0.7rem;
  margin-right: 0.7rem;
  display: block;
}
.card-homepage .card-body {
  padding-left: 0;
  padding-right: 0;
}
@media (max-width: 767px) {
  .card-homepage .card-inner-frame {
    width: 50%;
    min-height: 0;
  }
  .card-homepage .card-inner-frame .card-img-wrapper {
    height: auto;
  }
  .card-homepage .card-inner-frame .card-title {
    position: absolute;
    right: -100%;
    width: 100%;
    top: 0;
    margin: 0;
    padding: 0 0.7rem;
  }
}
/* mala vec pre skupina-zse */
.homepage-cards-container {
  margin-top: -3rem;
}
.card-homepage-skupina {
  border: 1px solid #cbcbcb;
  transition: all 0.4s ease;
}
.card-homepage-skupina.red-border {
  border: 1px solid #f21c0a;
}
.card-homepage-skupina .card-title {
  margin-top: 0.7rem;
  margin-left: 0.7rem;
  margin-right: 0.7rem;
  display: block;
}
.card-homepage-skupina .card-img-wrapper {
  height: 125px;
  width: 100%;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.card-homepage-skupina .card-img {
  object-fit: cover;
  height: 125px;
}
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .card-homepage-skupina .card-img {
    height: auto;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.card-homepage-skupina .card-text {
  margin-left: 0.7rem;
  margin-right: 0.7rem;
  font-size: 0.939rem;
  flex: 1 1 auto;
}
.card-homepage-skupina .card-footer {
  padding-left: 0.7rem;
  padding-right: 0.7rem;
}
@media (max-width: 575px) {
  .homepage-cards-container ~ .carousel {
    display: none;
  }
  .homepage-cards-container {
    margin-top: 0;
    padding-left: 0;
    padding-right: 0;
  }
  .homepage-cards-container .card-grid.mx-1 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .homepage-cards-container .card-grid.mx-1 .card-tile {
    padding-left: 0;
    padding-right: 0;
  }
}
.card-big-image-top {
  border: 1px solid #cbcbcb;
}
.card-big-image-top .card-title {
  margin-top: 0.7rem;
  margin-left: 0.7rem;
  margin-right: 0.7rem;
  margin-bottom: 0;
  display: block;
  text-align: center;
}
.card-big-image-top .card-img-wrapper {
  height: 220px;
  width: 100%;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.card-big-image-top .card-img {
  object-fit: cover;
  height: 220px;
}
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .card-big-image-top .card-img {
    height: auto;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.card-big-image-top .card-text {
  margin-left: 0.7rem;
  margin-right: 0.7rem;
  margin-bottom: 0.7rem;
  font-size: 0.939rem;
  flex: 1 1 auto;
  text-align: center;
}
.card-noimg .card-body {
  padding-top: 0;
}
.card-noimg .card-text {
  padding-top: 0.4rem;
}
.card-info-boxes > .card {
  padding: 1.3rem 0.9rem;
}
.card-info-boxes > .card .card-header {
  padding-top: 0;
  padding-bottom: 0;
}
.card-info-boxes > .card .card-body {
  padding-bottom: 0;
}
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .card {
    width: 100%;
  }
}
.card .img-cover {
  max-height: 250px;
  width: 100%;
  overflow: hidden;
  height: 250px;
  background-repeat: no-repeat;
  background-size: cover;
}
.card .card-img {
  -o-object-fit: cover;
  object-fit: cover;
}
/* Homepage - magazin box */
.card-magazin .card-body {
  padding: 0;
}
.card-magazin .card-body .card-text ul {
  padding-top: 1.25rem;
}
.card-magazin .card-footer {
  padding: 0;
}
.card-bordered {
  border: 1px solid #cbcbcb;
}
/* Rozcestník na stránke Zariadenia do domácnosti */
.card-katalog-rozc1 {
  border: 1px solid #cbcbcb;
  color: #f21c0a;
  text-decoration: none;
  background-color: #FFF;
}
.card-katalog-rozc1:hover {
  text-decoration: none;
}
.card-katalog-rozc1 .card-header {
  padding-top: 2rem;
  padding-bottom: 0;
}
.card-katalog-rozc1 .card-body {
  padding-bottom: 2rem;
}
.card-katalog-rozc1.hide {
  background-color: transparent;
  border: 1px solid transparent;
}
@media (max-width: 767px) {
  .card-katalog-rozc1 .card-body {
    padding: 1rem 0.5rem 2rem 0.5rem;
  }
}
.card-produkt-box {
  border: 1px solid #cbcbcb;
}
.card-produkt-box .card-img-top {
  height: 180px;
  object-fit: cover;
  border-bottom: 1px solid #cbcbcb;
}
.card-produkt-box .card-title {
  color: #f21c0a;
  height: 4rem;
}
.card-produkt-box .card-title.black {
  color: #000;
}
.card-produkt-box .card-title.secondary {
  color: #000;
}
.card-produkt-box .card-body ul {
  padding-left: 1em;
}
.card-produkt-box .card-body ul li {
  margin-bottom: 0.162rem;
  font-size: 0.95rem;
}
.card-produkt-box .cena {
  /*font-weight: 600;*/
  font-size: 1.2rem;
  margin-bottom: 1rem;
}
.card-produkt-box .card-text {
  margin-bottom: 1rem;
}
.card-produkt-box .card-text .btn {
  display: inline-block;
  font-size: 1rem;
  margin-right: 0.25rem;
  margin-bottom: 0.25rem;
}
.card-produkt-box .card-html-desc {
  margin-bottom: 1rem;
}
.card-produkt-box .bonuses {
  margin-bottom: 1rem;
}
.card-produkt-box .bonus {
  color: #9daf37;
  height: 4rem;
}
.card-produkt-box .bonus i {
  padding-right: 0.7rem;
  font-size: 1.5rem;
  display: inline-block;
  vertical-align: top;
}
.card-produkt-box .bonus .icon-elektrina:before {
  color: #9daf37;
}
.card-produkt-box .bonus span {
  line-height: 1.5rem;
  display: inline-block;
  max-width: calc(100% - 50px);
}
.card-promo-box {
  border: 1px solid #cbcbcb;
  background-size: cover;
  background-position: top right;
}
.card-promo-box .card-footer {
  padding-right: 3rem;
  padding-bottom: 1.5rem;
  position: relative;
}
.card-promo-box .card-title {
  color: #000;
  font-weight: 700;
  text-decoration: none;
}
.card-promo-box .card-title i {
  font-size: 1rem;
  position: absolute;
  right: 1.25rem;
  bottom: 1.75rem;
}
.card-pomoc-podpora {
  border: 1px solid #cbcbcb;
}
.card-pomoc-podpora .card-header {
  padding-top: 2rem;
  padding-bottom: 1.1rem;
}
.card-pomoc-podpora .with-image {
  height: 8.2rem;
  padding: 1rem 1.25rem;
}
.card-pomoc-podpora .with-image img {
  max-height: 6rem;
  max-width: 6rem;
}
.card-pomoc-podpora .with-image img.no-limit {
  max-height: 100%;
  max-width: 100%;
}
.card-pomoc-podpora .card-body {
  padding-top: 0;
  padding-bottom: 0;
}
.card-pomoc-podpora .card-body .card-name {
  color: #f21c0a;
  text-decoration: none;
}
.card-pomoc-podpora .card-body ul {
  margin-top: 1.5rem;
  padding-left: 1em;
}
@media (max-width: 767px) {
  .card-pomoc-podpora .card-body {
    padding: 0 0.5em;
  }
  .card-pomoc-podpora .card-body ul {
    padding-left: 1.5em;
  }
  .card-pomoc-podpora .card-body ul li {
    margin-bottom: 0em;
  }
}
.card-image-left {
  flex-direction: row;
  /* flip card */
}
.card-image-left .left-image {
  flex: 0 0 62.5%;
  height: 336px;
  position: relative;
  transition: transform 0.8s;
  transition-delay: 0.5s;
  transform-style: preserve-3d;
}
.card-image-left .left-image img {
  display: block;
  height: auto;
  width: 100%;
  max-height: 400px;
}
.card-image-left .left-image.inCenter {
  transform: rotateY(180deg);
}
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .card-image-left .left-image.inCenter {
    transform: none;
  }
}
.card-image-left .left-image .flip-card-front,
.card-image-left .left-image .flip-card-back {
  position: absolute;
  width: 100%;
  height: auto;
  backface-visibility: hidden;
}
.card-image-left .left-image .flip-card-back {
  transform: rotateY(180deg);
}
.card-image-left .right-text {
  flex: 0 0 37.5%;
  padding: 1rem;
  background-color: #f1f1f1;
  font-size: 0.9rem;
  display: flex;
  flex-direction: column;
}
.card-image-left .right-text .text-content {
  overflow-y: auto;
}
.card-image-left .big-letter {
  font-size: 6rem;
  line-height: 5rem;
}
#ie-warning {
  display: none;
}
#ie-warning .card-ie-warning {
  border: 1px solid #f21c0a;
  color: #f21c0a;
  padding: 0.5rem;
  display: flex;
}
#ie-warning .card-ie-warning .icon {
  width: 50px;
  margin-right: 0.8rem;
}
#ie-warning .card-ie-warning .icon img {
  width: 100%;
  height: auto;
}
#ie-warning .card-ie-warning .text {
  padding-top: 0.2rem;
}
#ie-warning .card-ie-warning .text a {
  color: #f21c0a;
}
@media (max-width: 767px) {
  .card-image-left {
    flex-direction: column;
    display: block;
  }
}
.card-top-img {
  height: 170px;
  width: 100%;
}
.card-list-img-wrap .card-list-img {
  height: 170px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
/* DIVIDER */
.divider {
  background-color: transparent;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2IiBoZWlnaHQ9IjEwMDAiPgo8cmVjdCB3aWR0aD0iMSIgaGVpZ2h0PSI1IiBmaWxsPSIjMDAwMDAwIiBvcGFjaXR5PSIwLjEiPjwvcmVjdD4KPHJlY3QgeD0iMSIgd2lkdGg9IjUiIGhlaWdodD0iNSIgZmlsbD0iI0Y5RjlGOSIgb3BhY2l0eT0iMC4wIj48L3JlY3Q+Cjwvc3ZnPg==");
  background-repeat: repeat-x;
  background-size: initial;
  min-height: 0.5em;
  border: 0 none;
  position: relative;
  z-index: 1;
}
.divider + .carousel {
  margin-top: -0.5em;
}
/* SHADOWS */
.shadow-top {
  position: relative;
}
.shadow-top:before {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  content: "";
  height: 10px;
  display: block;
  background-image: linear-gradient(#d2d2d2, #FFF);
}
.shadow-bottom {
  position: relative;
}
.shadow-bottom:after {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  height: 10px;
  display: block;
  background-image: linear-gradient(#FFF, #d2d2d2);
}
/* NAVIGATION */
nav a,
.nav a {
  text-decoration: none;
}
nav .nav-link.active,
.nav .nav-link.active {
  color: #f21c0a;
}
/* Breadcrumb */
.breadcrumb a {
  text-decoration: underline;
}
.breadcrumb a.active {
  text-decoration: none;
}
/* Desktop Menu */
.main-menu {
  border-top: 1px solid #bfbfbf;
  border-bottom: 1px solid #bfbfbf;
}
/* Mobile Menu */
#mobile-menu .menu-root {
  position: fixed;
  z-index: 1000;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: linear-gradient(#e6e7e8, #FFF);
  border-top: 1px solid #bfbfbf;
}
#mobile-menu .menu-root ul {
  list-style: none;
  display: table;
  width: 100%;
  padding: 0;
  margin: 0;
}
#mobile-menu .menu-root ul li {
  display: table-cell;
  text-align: center;
}
#mobile-menu .menu-root ul li.third {
  width: 33%;
}
#mobile-menu .menu-root ul li a {
  display: inline-block;
  margin-bottom: 0;
  padding: 5px 11px 11px 11px;
  border: 1px solid transparent;
  border-top: 1px solid transparent;
  position: relative;
  font-size: 0.92925rem;
}
#mobile-menu .menu-root ul li a.active {
  background: #FFF;
  border: 1px solid #bfbfbf;
  border-top: 1px solid #FFF;
  margin-top: -1px;
}
#mobile-menu .menu-root ul li a.active:before {
  content: "";
  display: block;
  top: -1px;
  left: 0;
  right: 0;
  height: 1px;
  background-color: #FFF;
}
#mobile-menu .menu-root ul li a:hover {
  text-decoration: none;
}
#mobile-menu .menu-root ul li a i {
  display: block;
  font-size: 2rem;
  color: #f21c0a;
}
#mobile-menu .menu-card {
  position: fixed;
  z-index: 900;
  top: 0;
  bottom: 50px;
  left: 0;
  right: 0;
  background-color: #FFF;
  overflow-x: hidden;
  overflow-y: scroll;
  display: none;
}
#mobile-menu .menu-card.active {
  display: block;
}
#mobile-menu .menu-card .mobile-menu-header {
  padding: 27px 20px;
  border-bottom: 1px solid #bfbfbf;
  font-size: 1.561764705882353rem;
  letter-spacing: -0.025em;
  background-color: #FFF;
}
#mobile-menu .menu-card .menu-card-body {
  padding: 33px 21px;
  position: relative;
}
#mobile-menu .menu-card .menu-card-body:before {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  content: "";
  height: 10px;
  display: block;
  background-image: linear-gradient(#d2d2d2, #FFF);
}
#mobile-menu .menu-card > ul > li {
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #bfbfbf;
}
#mobile-menu .menu-card ul {
  list-style: none;
  padding: 0;
}
#mobile-menu .menu-card ul li {
  margin: 0;
  font-size: 1.561764705882353rem;
  letter-spacing: -0.025em;
  padding-left: 20px;
}
#mobile-menu .menu-card ul li a {
  text-decoration: none;
}
#mobile-menu .menu-card ul li.has-children {
  position: relative;
  padding-top: 20px;
  padding-bottom: 20px;
}
#mobile-menu .menu-card ul li.has-children.active a {
  color: #f21c0a;
}
#mobile-menu .menu-card ul li.has-children:after {
  position: absolute;
  right: 20px;
  font-size: 3.123529411764706rem;
  line-height: 2rem;
  content: "+";
  color: #f21c0a;
}
#mobile-menu .menu-card ul li.has-children.active:after {
  content: "-";
}
#mobile-menu .menu-card ul li.mobile-menu-section {
  padding: 0;
  position: relative;
  display: none;
}
#mobile-menu .menu-card ul li.mobile-menu-section a {
  padding-top: 20px;
  padding-bottom: 20px;
  display: block;
}
#mobile-menu .menu-card ul li.mobile-menu-section:before {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  content: "";
  height: 10px;
  display: block;
  background-image: linear-gradient(#d2d2d2, #FFF);
}
#mobile-menu .menu-card ul li.mobile-menu-section:after {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  height: 10px;
  display: block;
  background-image: linear-gradient(#FFF, #d2d2d2);
}
#mobile-menu .menu-card ul li.mobile-menu-section.active {
  display: block;
}
#mobile-menu .menu-card ul li:last-child {
  border-bottom: 0 none;
}
#mobile-menu-account .btn.btn-outline-secondary {
  min-width: 150px;
  margin-right: 23px;
  text-align: left;
}
#mobile-menu-account .btn.btn-outline-secondary:last-child {
  margin-right: 0;
}
body.mobile-menu-active {
  overflow: hidden;
  width: 100%;
  height: 100%;
}
@media (max-width: 576px) {
  .contacts-footer {
    margin-top: 1rem;
  }
}
.error-page-main-text {
  font-size: 9.4rem;
}
.carousel .divider {
  position: absolute;
  z-index: 5;
  width: 100%;
}
.carousel .breadcrumb-container {
  position: absolute;
  top: 1rem;
  z-index: 5;
}
.carousel .carousel-item {
  width: 100%;
}
.carousel .carousel-item.no-overflow {
  max-height: 460px;
  overflow: hidden;
}
.carousel .carousel-item img {
  width: 100%;
  object-fit: cover;
}
.carousel .carousel-item img.second {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
.carousel .carousel-item .mobile {
  display: none;
}
.carousel .carousel-item .desktop {
  display: block;
}
.carousel .carousel-caption {
  left: 0;
  right: 0;
  padding-left: 2rem;
  bottom: 2rem;
}
.carousel .carousel-caption .carousel-caption-box {
  background: rgba(255, 255, 255, 0.6);
  color: #000;
  padding: 1.5em;
}
.carousel .carousel-caption a.btn-primary {
  margin-top: 1em;
}
@media (max-width: 767px) {
  .carousel {
    border: 1px solid #bfbfbf;
  }
  .carousel .carousel-item {
    overflow: hidden;
    height: auto;
    max-height: 30rem;
  }
  .carousel .carousel-item img {
    height: 100%;
    object-fit: cover;
  }
  .carousel .carousel-item .mobile {
    display: block;
  }
  .carousel .carousel-item .desktop {
    display: none;
  }
  .carousel .carousel-caption {
    padding: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }
  .carousel .carousel-caption .carousel-caption-box {
    width: 100%;
    padding: 1.5em;
  }
  .carousel .carousel-caption .carousel-caption-box h1 {
    margin-bottom: 0;
    font-size: 2.62314rem;
  }
  .carousel .carousel-caption .carousel-caption-box br {
    display: none;
  }
}
/* ==========================================================================
   Carousel
   ========================================================================== */
.carousel-height {
  height: 30em;
}
.css-table {
  display: table;
}
.css-table-cell {
  display: table-cell;
  vertical-align: middle;
}
.carousel-wrapper {
  clear: both;
}
.carousel-wrapper .carousel {
  list-style: none;
  padding: 0;
  margin: 0;
  height: 30em;
}
.carousel-wrapper .carousel .carousel-dots {
  position: absolute;
  bottom: 1em;
  left: 2em;
}
.carousel-wrapper .carousel .carousel-dots ul,
.carousel-wrapper .carousel .carousel-dots li {
  list-style: none;
  padding: 0;
  margin: 0;
}
.carousel-wrapper .carousel .carousel-dots li {
  display: inline-block;
  border-radius: 50%;
  border: 1px solid #000;
  width: 0.8em;
  height: 0.8em;
  margin-right: 0.1em;
  background-color: #FFF;
  cursor: pointer;
}
.carousel-wrapper .carousel .carousel-dots li.current {
  background-color: #000;
}
.carousel-wrapper .carousel .carousel-slide {
  height: 30em;
  list-style: none;
  padding: 0;
  margin: 0;
  position: relative;
  vertical-align: middle;
}
.carousel-wrapper .carousel .carousel-slide .css-table {
  height: 30em;
  width: 26%;
}
.carousel-wrapper .carousel .carousel-slide .carousel-slide-img {
  height: 30em;
  background-size: cover;
  background-position: center center;
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.carousel-wrapper .carousel .carousel-slide .slide-text-wrapper {
  position: relative;
  z-index: 2;
  display: table;
  height: 90%;
}
.carousel-wrapper .carousel .carousel-slide .slide-text-wrapper .slide-text {
  display: table-cell;
  position: relative;
  vertical-align: top;
  text-align: left;
  background-color: rgba(255, 255, 255, 0.9);
  color: #000;
  padding: 2em;
}
.carousel-wrapper .carousel .carousel-slide .slide-text-wrapper .slide-text h3,
.carousel-wrapper .carousel .carousel-slide .slide-text-wrapper .slide-text h4 {
  margin-bottom: 1em;
  font-size: 1.34874rem;
}
.carousel-wrapper .carousel .carousel-slide .slide-text-wrapper .slide-text p {
  font-size: 0.9em;
}
.carousel-wrapper .carousel .carousel-controls {
  font-size: 1.5em;
  height: 2em;
  position: absolute;
  left: -0.2em;
  right: -0.2em;
  top: 1.5em;
}
.carousel-wrapper .carousel .carousel-controls a {
  color: #000;
  text-decoration: none;
  background: none;
}
.carousel-wrapper .carousel .carousel-controls a:hover,
.carousel-wrapper .carousel .carousel-controls a:focus,
.carousel-wrapper .carousel .carousel-controls a:active {
  color: #000;
  outline: 0;
}
.carousel-wrapper .carousel .carousel-controls a.carousel-control-prev {
  float: left;
}
.carousel-wrapper .carousel .carousel-controls a.carousel-control-next {
  float: right;
}
/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
  .carousel-height {
    height: auto;
  }
  .carousel-wrapper .carousel {
    height: auto;
  }
  .carousel-wrapper .carousel .carousel-slide {
    height: auto;
  }
  .carousel-wrapper .carousel .carousel-slide .carousel-slide-img {
    position: static;
    height: 20em;
    background-position: right center;
  }
  .carousel-wrapper .carousel .carousel-slide .container {
    padding: 0;
  }
  .carousel-wrapper .carousel .carousel-slide .css-table,
  .carousel-wrapper .carousel .carousel-slide .css-table-cell {
    display: block;
    height: auto;
    width: 100%;
  }
  .carousel-wrapper .carousel .carousel-slide .slide-text-wrapper {
    display: block;
    height: auto;
  }
  .carousel-wrapper .carousel .carousel-slide .slide-text-wrapper .slide-text {
    display: block;
    width: auto;
    height: auto;
  }
  .carousel-wrapper .carousel .carousel-controls {
    top: 2.5em;
  }
  .carousel-wrapper .carousel .carousel-controls .carousel-control-next {
    width: auto;
  }
  .carousel-wrapper .carousel .carousel-controls .carousel-control-prev {
    width: auto;
  }
}
.breadcrumb-item {
  margin-bottom: 0.5rem;
}
.breadcrumb {
  font-size: 0.824em;
}
.my-gallery {
  width: 100%;
  float: left;
}
.my-gallery a {
  position: relative;
  padding-bottom: 75%;
  display: block;
  overflow: hidden;
}
.my-gallery img {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
}
.my-gallery figure,
.my-gallery .gallery-more {
  float: left;
  margin: 0 2% 2% 0;
  width: 31%;
  border: 1px solid #bfbfbf;
}
.my-gallery figure:first-child,
.my-gallery .gallery-more:first-child {
  display: block;
  width: 100%;
  border: none;
}
.my-gallery figure:first-child a,
.my-gallery .gallery-more:first-child a {
  padding: 0;
}
.my-gallery figure:first-child img,
.my-gallery .gallery-more:first-child img {
  position: static;
  height: auto;
}
.my-gallery .gallery-more {
  text-align: center;
  vertical-align: middle;
}
.my-gallery .gallery-more .text {
  text-decoration: underline;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  top: 50%;
  margin-top: -0.5em;
}
.my-gallery figcaption {
  display: none;
}
@media (max-width: 730px) {
  .my-gallery figure {
    width: 31%;
  }
  .my-gallery figure:first-child {
    display: block;
    width: 100%;
  }
  .my-gallery .gallery-more {
    clear: left;
    width: auto;
  }
}
/* skupinazse video link */
.video-preview {
  background-color: #f1f1f1;
  width: 300px;
}
.video-preview img {
  max-height: 225px;
  max-width: 100%;
}
.video-preview .video-preview-description {
  padding: 1rem 1.5rem;
}
.video-preview .video-preview-description .video-preview-description-title {
  font-weight: 500;
  font-size: 1.3rem;
}
.video-preview .video-preview-description .video-preview-description-text {
  margin-top: 0.5rem;
  font-size: 1rem;
}
.video-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.85);
  z-index: 2018;
  pointer-events: auto;
}
.video-overlay .player-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 720px;
  height: 445px;
  margin: auto;
  z-index: 2019;
}
.video-overlay .player-wrapper .close-button {
  text-align: right;
  color: #cbcbcb;
  margin-bottom: 1rem;
  cursor: pointer;
  float: right;
}
@media (max-width: 730px) {
  .video-overlay .player-wrapper {
    width: 100vw;
    height: 70vh;
  }
  .video-overlay .player-wrapper iframe {
    width: 100vw;
    height: 56.25vh;
  }
}
.video-iframe-div {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;
}
.video-iframe-div iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.product-info .product-text {
  margin-bottom: 1.5em;
}
.product-info .price-box {
  font-weight: 700;
  margin-bottom: 1em;
}
.product-info .price-box .price {
  font-size: 1.062rem;
  display: inline-block;
}
.product-info .price-box small {
  color: #4d4d4d;
  display: inline-block;
}
.product-info .buttons {
  margin-top: 2em;
  margin-bottom: 2em;
}
.accordion > a {
  text-decoration: none;
  font-family: "Polo", sans-serif;
}
.accordion > a:hover {
  color: #000;
}
.accordion .card {
  border: 1px solid #bfbfbf;
  border-bottom: 0;
}
.accordion .card .card-header {
  padding: 0;
  position: relative;
  cursor: pointer;
  min-height: 2.8rem;
}
.accordion .card .card-header .row {
  margin: 0.75rem;
  vertical-align: middle;
}
.accordion .card .card-header.with-icon .row {
  padding-right: 2.2rem;
}
.accordion .card .card-header.with-icon:after {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: block;
  width: 3.2rem;
  font-size: 2.8rem;
  padding: 0 0.85rem;
  line-height: 2.8rem;
  text-align: center;
  border-left: 1px solid #bfbfbf;
  color: #f21c0a;
}
.accordion .card .card-header.with-icon.plus:after {
  content: '-';
}
.accordion .card .card-header.with-icon.plus.collapsed:after {
  content: '+';
}
.accordion .card .card-header.with-icon.arrow:after {
  font-family: 'icomoon' !important;
  content: "\e904";
  font-size: 1rem;
  font-weight: bold;
}
.accordion .card .card-body {
  border-top: 1px solid #bfbfbf;
  box-shadow: inset 0px 10px 10px -10px #bfbfbf, inset 0px -10px 10px -10px #bfbfbf;
}
.accordion .card .card-body .container-fluid {
  padding: 0;
  margin-top: 0 !important;
}
.accordion .card:last-of-type {
  border-bottom: 1px solid #bfbfbf;
}
.claim-info-box {
  background-color: #f21c0a;
  color: #FFF;
  position: relative;
  padding: 1em;
  padding-left: 4.5em;
}
.claim-info-box .heading {
  font-weight: 700;
  font-size: 1.062rem;
  margin-bottom: 0;
}
.claim-info-box p {
  padding-bottom: 0;
}
.claim-info-box i {
  color: #fbfd03;
  position: absolute;
  left: 0.1666em;
  top: 0.1666em;
  font-size: 3em;
}
.jumbotron.info-box {
  position: relative;
  background-color: #f1f1f1;
}
.jumbotron.info-box .btn-close {
  position: absolute;
  right: 1rem;
  top: 1rem;
}
.jumbotron.info-box hr {
  margin-left: -1rem;
  margin-right: -1rem;
  border-color: #a3a3a3;
}
.btn-live-agent {
  background-color: #9daf37;
  color: #FFF;
  position: fixed;
  right: 0;
  bottom: 3rem;
  padding: 0.8rem;
  cursor: pointer;
}
@media (max-width: 767px) {
  .btn-live-agent {
    display: none;
  }
}
.btn-live-agent i {
  vertical-align: middle;
  margin-left: 0.5rem;
}
table {
  width: 100%;
}
table th {
  font-weight: normal;
  color: #666;
  border-bottom: 1px solid #cbcbcb;
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
}
table td {
  padding-top: 0.65rem;
  padding-bottom: 0.65rem;
  vertical-align: top;
}
table tr.bottom-border {
  border-bottom: 1px solid #cbcbcb;
}
table.download tbody:before {
  content: "&nbsp;";
  display: block;
  line-height: 1.3em;
  color: transparent;
}
table.download .name {
  width: 45%;
}
table.download .down {
  width: 20%;
}
table.download a {
  color: #f21c0a;
  text-decoration: none;
}
/* TEMPLATE INCLUDE */
/* HEADER */
header {
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
}
header .logo {
  float: left;
  clear: right;
  margin-left: 1.062rem;
  margin-right: 1.062rem;
}
header .logo.big img {
  height: 70px;
}
header .logo img {
  height: 50px;
}
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  header .logo img {
    width: 270px;
  }
}
header .header-icon {
  font-size: 45px;
  line-height: 45px;
  height: 50px;
}
header .header-icon a {
  display: inline-block;
  text-decoration: none;
}
header .header-icon a:hover {
  color: inherit;
  text-decoration: none;
}
header .header-icon img {
  height: 50px;
  transform: translateY(-2px);
}
header .lang-menu {
  float: right;
  clear: both;
}
header .lang-menu .nav-link {
  font-size: 0.882rem;
  padding: 0.5rem;
  padding-right: 0;
}
header .lang-menu .nav-link.active {
  color: #000;
}
header .segment-menu {
  float: left;
  margin-top: 0.5em;
}
header .search-form,
header .user-menu {
  margin-top: .5em;
}
header .search-form .btn i,
header .user-menu .btn i {
  font-size: 1.5em;
  top: 0.1em;
  right: 0;
}
header .search-form .input-group > .search-input {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
header .search-form .search-input {
  width: 280px;
  padding-right: 40px;
}
header .search-form .search-button {
  background-color: transparent;
  position: absolute;
  padding: 0;
  top: 0.1em;
  right: 0;
  color: #f21c0a;
  z-index: 5;
}
header .search-form.skupina-zse {
  float: right;
}
.header-mobile {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.header-mobile .logo {
  margin-left: 0;
}
.header-mobile .logo img {
  height: 3rem;
}
.header-mobile .lang-menu {
  position: absolute;
  top: 0;
  right: 12px;
}
.header-mobile .lang-menu a {
  padding: 0;
}
/* FOOTER */
footer {
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
  background-color: #f1f1f1;
  padding-bottom: 1em;
}
footer .copyright {
  font-size: 0.824rem;
}
footer .social-icons {
  text-align: center;
}
@media (min-width: 768px) {
  footer .social-icons {
    text-align: left;
  }
}
footer .social-icons a {
  font-size: 1.47rem;
  text-decoration: none;
  color: #f21c0a;
}
/* MAIN ZONE */
main {
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
}
main.with-bg-img {
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: top right;
}
@media (max-width: 767px) {
  main.with-bg-img {
    background-size: 100%;
    background-position: 100% 0rem;
    position: relative;
  }
  main.with-bg-img .content {
    padding-top: 8rem;
  }
}
